$(function() {
    "use strict";
    $(function() {
        $(".preloader").fadeOut()
    }), jQuery(document).on("click", ".mega-dropdown", function(o) {
        o.stopPropagation()
    }), jQuery(document).on("click", ".navbar-nav > .dropdown", function(o) {
        o.stopPropagation()
    }), $(".dropdown-submenu").click(function() {
        $(".dropdown-submenu > .dropdown-menu").toggleClass("show")
    }), $("body").trigger("resize");
    var o = $(window);
    o.on("load", function() {
        var e = o.scrollTop(),
            n = $(".topbar");
        e > 100 ? n.addClass("fixed-header animated slideInDown") : n.removeClass("fixed-header animated slideInDown")
    }), $(window).scroll(function() {
        $(window).scrollTop() >= 100 ? ($(".topbar").addClass("fixed-header animated slideInDown"), $(".bt-top").addClass("visible")) : ($(".topbar").removeClass("fixed-header animated slideInDown"), $(".bt-top").removeClass("visible"))
    })

    $('#subscribeForm').submit(function(e){
        e.preventDefault();
        var formData = $(this).serialize();
        var the_url = $(this).attr('action');
        $('#subscribeForm .form-control-feedback').addClass('d-none');
        $('#subscribeForm .form-group').removeClass('has-danger');
        $.post(the_url, formData, function() {
            
        })
        .done(function() {
            $('#subscribeForm').html('<div class="d-flex flex-column justify-content-center text-success-gradiant"><span class="h1">Done</span><i class="fa fa-check-circle-o fa-4x" aria-hidden="true"></i></div>').prev().remove();
        })
        .fail(function(data) {
            if (data.responseJSON.errors.email){
                $('#subscribeForm input[name="email"]').next().removeClass('d-none').parent().addClass('has-danger');
            }
            if (data.responseJSON.errors.first_name){
                $('#subscribeForm input[name="first_name"]').next().removeClass('d-none').parent().addClass('has-danger');
            }
            if (data.responseJSON.errors.last_name){
                $('#subscribeForm input[name="last_name"]').next().removeClass('d-none').parent().addClass('has-danger');
            }
        });
    });


    $('#noOffers').click(function(e){
        $('.unsubscribetOffersWrapper').fadeOut();
        $('.unsubscribetFormWrapper').removeClass('d-none');
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('body').on('click', '.click-out', function(e){
        var casino_id = $(this).attr('data-casino-id');
        var link = $(this).attr('href');
        $.ajax({
           type:'POST',
           url:'/click-out',
           data:{casino_id:casino_id, link:link}
       });
    })

    $('#unsubscribeForm').submit(function(e){
        e.preventDefault();
        var formData = $(this).serialize();
        var the_url = $(this).attr('action');
        $('#unsubscribeForm .form-control-feedback').addClass('d-none');
        $('#unsubscribeForm .form-group').removeClass('has-danger');
        $('#unsubscribeForm button').text('Unsubscribing ...');
        $.post(the_url, formData, function() {

        })
        .done(function() {
            $('.unsubscribetFormWrapper h4').hide();
            $('#unsubscribeForm').html('<div class="d-flex flex-column justify-content-center text-success-gradiant"><span class="h1">Done</span><i class="icon-Thumbs-DownSmiley my-5" style="font-size:144px"></i></div>').prev().remove();
        })
        .fail(function(data) {
            $('#unsubscribeForm button').text('Unsubscribe');
            if (data.responseJSON.errors.email){
                $('#unsubscribeForm input[name="email"]').next().removeClass('d-none').parent().addClass('has-danger');
            }
        });
    });
    



    /**
     * SEARCH
     */
    var searchOpenCtrl = document.getElementById('btn-search');
    var searchCloseCtrl = document.getElementById('btn-search-close');
    var searchContainer = document.querySelector('.siteSearch');
    var inputSearch = searchContainer.querySelector('.search__input');

    function searchEventsInit() {
        initSearchEvents();   
    }

    function initSearchEvents() {
        searchOpenCtrl.addEventListener('click', openSearch);
        searchCloseCtrl.addEventListener('click', closeSearch);
        document.addEventListener('keyup', function(ev) {
            // escape key.
            if( ev.keyCode == 27 ) {
                closeSearch();
            }
        });
    }

    function openSearch() {
        searchContainer.classList.add('open');
        setTimeout(function() {
            inputSearch.focus();
        }, 500);
    }

    function closeSearch() {
        searchContainer.classList.remove('open');
        searchContainer.classList.remove('searching');
        inputSearch.blur();
        inputSearch.value = '';
    }

    $('#theSearch').submit(function(e){
        e.preventDefault();
        var the_url = $(this).attr('action');
        var form_data = $(this).serialize();
        searchContainer.classList.add('searching');
        $.post(the_url, form_data, function(data){
            $('.search__results').html(data);
            searchContainer.classList.remove('searching');
        });
    }); 
    
    searchEventsInit();






    /**
     * CASINO SIDE FILTERS
     */
    $('#casinoFilters input').on('change', function(){
        $('#casinoFilters').submit();
    });
    $('#casinoFilters').submit(function(e){
        e.preventDefault();
        var the_url = $(this).attr('action');
        var form_data = $(this).serialize();      
        $('.preloader').show();  
        $.post(the_url, form_data, function(data){
            $('.shop-listing').html(data);
            $('.preloader').hide();
        });
    });

    /**
     * SLOTS SIDE FILTERS
     */
    $('#slotsFilters input').on('change', function(){
        $('#slotsFilters').submit();
    });
    $('#slotsFilters').submit(function(e){
        e.preventDefault();
        var the_url = $(this).attr('action');
        var form_data = $(this).serialize();      
        $('.preloader').show();  
        $.post(the_url, form_data, function(data){
            $('.shop-listing').html(data);
            $('.preloader').hide();
        });
    });
});
